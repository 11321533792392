import React from "react";
import Tile from "./Tile.js";
import "./Board.css";

class Board extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            highlightedTile: -1,
        };
    }
    renderTile(i) {
        return (
            <Tile
                key={i}
                value={this.props.tiles[i]}
                numColors={this.props.numColors}
                onClick={() => this.props.onClick(i)}
                onRightClick={(e) => this.props.onRightClick(e, i)}
                onMouseOver={() => this.setTileHighlight(i, true)}
                onMouseOut={() => this.setTileHighlight(i, false)}
                highlighted={this.isTileHighlighted(i)}
            />
        );
    }

    setTileHighlight(tileNum, isHovered) {
        this.setState({
            highlightedTile: isHovered ? tileNum : -1,
        });
    }

    isTileHighlighted(tileNum) {
        const rowLength = Math.sqrt(this.props.win.length);
        const horzRow = Math.floor(this.state.highlightedTile / rowLength);
        const vertRow = this.state.highlightedTile % rowLength;

        // remove highlights when no mouseover
        if (this.state.highlightedTile === -1) {
            return false;
        }

        // horz row highlights
        if (Math.floor(tileNum / rowLength) === horzRow) {
            return true;
        }

        // vert row highlights
        if (tileNum % rowLength === vertRow) {
            return true;
        }
    }

    renderTiles(rowLength, row) {
        let tiles = [];
        for (let i = 0; i < rowLength; i++) {
            tiles.push(this.renderTile(row * rowLength + i));
        }

        return tiles;
    }

    renderRowCount(isVertical, row) {
        let rowNums = [];
        let currCount = 0;
        let currColor = 0;
        const rowLength = Math.sqrt(this.props.win.length);
        for (let i = 0; i < rowLength; i++) {
            const tile = isVertical ? i * rowLength + row : i + row * rowLength;
            if (currCount === 0) {
                currCount++;
                currColor = this.props.win[tile];
            }
            // if new tile matches previous tile color, increment
            else if (this.props.win[tile] === currColor) {
                currCount++;
            }
            // if colors don't match and we're not counting blanks
            else if (this.props.win[tile] !== currColor && currColor > 0) {
                rowNums.push(
                    <span key={i} className={`color${currColor}`}>
                        {currCount}
                    </span>
                );
                currCount = 1;
                currColor = this.props.win[tile];
            }
            // this was a set of empties, so set to new color
            else {
                currCount = 1;
                currColor = this.props.win[tile];
            }
        }

        // final count after last row, if any
        if (currCount > 0 && currColor > 0) {
            rowNums.push(
                <span key="f" className={`color${currColor}`}>
                    {currCount}
                </span>
            );
        }

        // if no tiles filled, show 0
        if (rowNums.length === 0) {
            rowNums.push(<span key="0">0</span>);
        }

        return rowNums;
    }

    renderTopRow() {
        let board = [];
        let rows = [];
        rows.push(<div key={`corner-object`} className="board-corner"></div>);
        const rowLength = Math.sqrt(this.props.tiles.length);
        for (let i = 0; i < rowLength; i++) {
            rows.push(
                <div
                    key={`horz-row-${i}`}
                    className={`horz-row ${
                        i === this.state.highlightedTile % rowLength
                            ? "highlighted"
                            : ""
                    }`}
                >
                    {this.renderRowCount(true, i)}
                </div>
            );
        }
        board.push(
            <div key={`row-top`} className="horz-row-container">
                {rows}
            </div>
        );
        return board;
    }

    renderBoard() {
        let board = [];
        const rowLength = Math.sqrt(this.props.tiles.length);
        for (let i = 0; i < rowLength; i++) {
            board.push(
                <div key={`row-${i}`} className="board-row">
                    <div
                        key={`vert-row-${i}`}
                        className={`vert-row ${
                            i ===
                            Math.floor(this.state.highlightedTile / rowLength)
                                ? "highlighted"
                                : ""
                        }`}
                    >
                        {this.renderRowCount(false, i)}
                    </div>
                    {this.renderTiles(rowLength, i)}
                </div>
            );
        }
        return board;
    }

    determineVisualSize() {
        const rowLength = Math.sqrt(this.props.tiles.length);
        if (rowLength <= 10) {
            return "x10";
        } else if (rowLength <= 20) {
            return "x20";
        } else {
            return "x30";
        }
    }

    render() {
        return (
            <div id="game-container">
                <div id="game-board" className={this.determineVisualSize()}>
                    {this.renderTopRow()}
                    {this.renderBoard()}
                </div>
            </div>
        );
    }
}

export default Board;
