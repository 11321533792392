import React from "react";
import Board from "./Board.js";
import TopBar from "./TopBar.js";

class Game extends React.Component {
    constructor(props) {
        super(props);
        let wins = parseInt(localStorage.getItem("totalWins")) || 0;
        const numColors = 1;
        const randomFinal = [...new Array(25)].map(() =>
            Math.round(Math.random() * numColors)
        );
        this.state = {
            boardSize: 25,
            colors: numColors,
            tiles: Array(25).fill(0),
            final: randomFinal,
            totalWins: wins,
            win: false,
        };
        this.changeBoardSize = this.changeBoardSize.bind(this);
        this.changeColors = this.changeColors.bind(this);
    }

    isBoardMatch() {
        return this.state.tiles.every((tile, i) => {
            if (tile !== this.state.final[i]) {
                // check if the final tile is a zero and if the user has it marked
                if (
                    tile === this.state.colors + 1 &&
                    this.state.final[i] === 0
                ) {
                    return true;
                }
                return false;
            }
            return true;
        });
    }

    changeBoardSize(event) {
        const size = parseInt(event.target.value);
        this.setState({
            boardSize: size,
            tiles: Array(size).fill(0),
            final: [...new Array(size)].map(() =>
                Math.round(Math.random() * this.state.colors)
            ),
            win: false,
        });
    }

    changeColors(event) {
        const numColors = parseInt(event.target.value);
        this.setState({
            colors: numColors,
            tiles: Array(this.state.boardSize).fill(0),
            final: [...new Array(this.state.boardSize)].map(() =>
                Math.round(Math.random() * numColors)
            ),
            win: false,
        });
    }

    handleClick(i) {
        const current = this.state.tiles;
        if (this.state.tiles[i] < this.state.colors + 1) {
            current[i]++;
        } else {
            current[i] = 0;
        }
        this.setState({ tiles: current });

        if (!this.state.win && this.isBoardMatch()) {
            let wins = this.state.totalWins;
            wins += 1;
            localStorage.setItem("totalWins", wins);
            this.setState({
                totalWins: wins,
                win: true,
            });
        }
    }

    onRightClick(event, i) {
        event.preventDefault();
        const current = this.state.tiles;
        if (this.state.tiles[i] < this.state.colors + 1) {
            current[i] = this.state.colors + 1;
        } else {
            current[i] = 0;
        }
        this.setState({ tiles: current });
    }

    getClassName() {
        if (this.state.win === true) {
            return "solved";
        } else {
            return "unsolved";
        }
    }

    render() {
        return (
            <div id="game" className={this.getClassName()}>
                <TopBar
                    boardSize={this.state.boardSize}
                    changeBoardSize={this.changeBoardSize}
                    numColors={this.state.colors}
                    changeColors={this.changeColors}
                    totalWins={this.state.totalWins}
                    win={this.state.win}
                />
                <Board
                    win={this.state.final}
                    tiles={this.state.tiles}
                    numColors={this.state.colors}
                    onClick={(i) => this.handleClick(i)}
                    onRightClick={(event, i) => this.onRightClick(event, i)}
                />
            </div>
        );
    }
}

export default Game;
