import React from "react";
import "./Tile.css";

class Tile extends React.Component {
    getClassName() {
        let className = "tile";

        if (this.props.value < this.props.numColors + 1) {
            className += ` color${this.props.value}`;
        } else {
            className += " blocked";
        }

        if (this.props.highlighted) {
            className += " highlighted";
        }

        return className;
    }
    render() {
        return (
            <div
                className={this.getClassName()}
                onClick={this.props.onClick}
                onContextMenu={this.props.onRightClick}
                onMouseOver={this.props.onMouseOver}
                onMouseOut={this.props.onMouseOut}
            >
                <span>
                    {this.props.value === this.props.numColors + 1 ? "x" : ""}
                </span>
            </div>
        );
    }
}

export default Tile;
