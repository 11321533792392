import React from "react";
import "./TopBar.css";

class TopBar extends React.Component {
    render() {
        return (
            <div className="top-bar">
                <label>
                    Board size:
                    <select
                        value={this.props.boardSize}
                        onChange={this.props.changeBoardSize}
                    >
                        <option value="25">5x5</option>
                        <option value="64">8x8</option>
                        <option value="100">10x10</option>
                        <option value="225">15x15</option>
                        <option value="400">20x20</option>
                        <option value="625">25x25</option>
                        <option value="900">30x30</option>
                    </select>
                </label>
                <label>
                    Colors:
                    <select
                        value={this.props.numColors}
                        onChange={this.props.changeColors}
                    >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                </label>
                <span>Pxl Picross</span>
                <span>
                    {this.props.win ? "Solved!" : "Keep going"} - Total wins:{" "}
                    {this.props.totalWins}
                </span>
            </div>
        );
    }
}

export default TopBar;
